import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { useGetConfigQuery } from 'src/services/config/configAPI';
import auth0 from 'auth0-js';

export const configs = {
  API_TOKEN: localStorage.getItem('id_token'),
  BASE_URL: process.env.REACT_APP_BASE_URL ?? '/api', // DEV
  METHOD_GET: 'GET',
  METHOD_POST: 'POST',
  CONTENT_TYPE: 'application/json',
  COUNTRY_PREFIX: '1',
  /*TODO for datarock*/
  // AUTH_RESPONSE_TYPE: AUTH_RESPONSE_TYPE ?? "auth0",
  AUTH_REDIRECT_NATIVE_PREFIX: 'com.expo.tester://callback',
  AUTH_EXTRA_PARAM_NONCE: '232323',
  USE_APP_LOCAL_URL: true,
  AUTH_REDIRECT_ANDROID_WEBVIEW: 'https://app.payup.com',
  COUPA_AREA_CODE: 425,
  DEFAULT_LIMIT: 25,
  BASE_CURRENCY: '$',
  ENCRYPT_PHRESE: 'payup_coupa',
  AMPLITUDE_API_KEY: '6ba2875e9ffa7aea12605c31a5dc8636',
  OFFSET_LIMIT: 25,
  ONE_ROW_HEIGHT: 40,
  GRID_ROW_HEIGHLIGHT_TIME: 60, //60 secounds
  FULLSTORY_ORG_ID: 'o-1CZ4ZR-na1',
  CAROUSEL_ITEM_DISPLAY_TIME: 3000, // in milliseconds
  TRANSACTIONS_ROW_LIMIT: 20000,
};

export const prepareHeaders = (headers: any) => {
  const id_token = localStorage.getItem('id_token');
  headers.set('authorization', `Bearer ${id_token}`);
  headers.set('Content-Type', 'application/json');
  return headers;
};

export const prepareHeadersAuthLess = (headers: any) => {
  headers.set('Content-Type', 'application/json');
  return headers;
};

export const prepareBaseQuery = (isAuthLess: boolean) => {
  return fetchBaseQuery({
    baseUrl: configs.BASE_URL,
    prepareHeaders: isAuthLess ? prepareHeadersAuthLess : prepareHeaders,
  });
};

export const redirectionStorageFlags = {
  enableHodOn: 'enable_holdon',
  earlyPayouts: 'early-payout',
  reviewErrorBlock: 'review_error_block',
  validReEnterNineDigits: 'first_time_9_digit_ssn_added', // 'visible'
  approvedAccount: 'approved-account', // 'true' or 'false'
  failedCompany: 'failed_company', // 'true' or 'false',
  verificationFailed: 'verification-failed-diabled',
};

export const nativeBridgeTypes = {
  SELECTED_LOCALE: 'SELECTED_LOCALE',
  LOGIN_STATUS: 'LOGIN_STATUS',
  STRIPE_PUBLISHABLE_KEY: 'STRIPE_PUBLISHABLE_KEY',
  STRIPE_CLIENT_SECRET: 'STRIPE_CLIENT_SECRET',
  STRIPE_FIN_CONN_RESULT: 'STRIPE_FIN_CONN_RESULT',
};

export const localPrefs = {
  NOTIFY_FINANCES_PREF: 'NOTIFY_FINANCES_PREF',
  STATE_PRESSED: 'STATE_PRESSED',
};

export const notificationTypes = {
  PAYUP_FINANCIAL_CARD: 'PAYUP_FINANCIAL_CARD',
};

// set to 'true' when required to hide web app's tab bar when loaded inside the native app.
export const hideTabsOnNativeApp = false;

export const getWebAuth = () => {
  const { data, isSuccess } = useGetConfigQuery('');
  const clientID = isSuccess ? data?.auth0?.client_id : '';
  const domain = isSuccess ? data?.auth0?.custom_domain : '';

  const webAuth = new auth0.WebAuth({
    domain: domain,
    clientID: clientID,
  });

  return { webAuth, isSuccess };
};

// remove items from local storage when logout
export const removeFromLocalStorage = () => {
  localStorage.removeItem('id_token');
  localStorage.removeItem('approved-account');
  localStorage.removeItem('set_id_modal_states');
  localStorage.removeItem('isBack');
  localStorage.removeItem('menuNav');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('coupaObj');
  localStorage.removeItem('from_resend');
  localStorage.removeItem('from_login');
  localStorage.removeItem('from_signup');
  localStorage.removeItem('phone_verification');
  localStorage.removeItem('link-bank-details');
  localStorage.removeItem('cardIssue');
  localStorage.removeItem('vendorDetails');
  localStorage.removeItem(localPrefs.NOTIFY_FINANCES_PREF);
};

export const nativeHandlerForLogOut = () => {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({ type: nativeBridgeTypes.LOGIN_STATUS, isLoggedIn: false })
  );
};

export const getMyAccountHeader = (response?: any) => {
  const setHeader =
    response?.data?.accountByIdpId ??
    response?.data?.accountByPhone ??
    response?.data?.accountByEmail;
  return setHeader;
};

export const removeAuthCallbackRelatedStorages = () => {
  localStorage.removeItem('auth_callback_route');
};
