import { useTranslation } from 'react-i18next';

import { TransactionCardWrapper } from 'src/components/base/core';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Typography } from '@mui/material';

import {
  AfterBottomRow,
  TransactionRowIconArrow,
} from '../../CommonNestedStyles/CommonNestedStyles.style';

import { theme } from 'src/shared/theme/theme';

import { ITransactionSummaryCard } from 'src/models/component/base.model';

export const TransactionSummaryCard = ({
  valueType,
  valueAccount,
  valueRouting,
  valueMethod,
  valueMethodContent,
  valueId,
  valueDescription,
  valueDestination = 'PayUp Account',
  isIncomingPayment,
}: ITransactionSummaryCard) => {
  const { t } = useTranslation();

  return (
    <TransactionCardWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          marginBottom: 1,
          marginTop: '2px',
          cursor: 'pointer',
          width: '100%',
        }}
      >
        <Typography
          component="p"
          variant="h2ExtraBold"
          textTransform={'uppercase'}
          sx={{
            marginBottom: 1,
            display: 'inline-flex',
            alignItems: 'center',
            color: 'gray.400',
          }}
        >
          {t('finance.transaction.details.summaryCard.heading')}
        </Typography>
      </Box>

      <Box display={'flex'} flexDirection={'column'}>
        <AfterBottomRow className="content icon_available">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('finance.transaction.details.summaryCard.type')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography
              variant="h2"
              component={'p'}
              color={'gray.500'}
              fontWeight={300}
              textTransform={'capitalize'}
            >
              {valueType ?? '----'}
            </Typography>
          </Box>
        </AfterBottomRow>

        {isIncomingPayment && (
          <AfterBottomRow className="content icon_available">
            <Box className="content_left">
              <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
                {t('finance.transaction.details.summaryCard.destination')}
              </Typography>
            </Box>
            <Box className="content_right">
              <Typography
                variant="h2"
                component={'p'}
                color={'gray.500'}
                fontWeight={300}
                textTransform={'capitalize'}
              >
                {valueDestination ?? '----'}
              </Typography>
            </Box>
          </AfterBottomRow>
        )}

        <AfterBottomRow className="content icon_available">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('finance.transaction.details.summaryCard.account')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2" component={'p'} color={'gray.500'} fontWeight={300}>
              {valueAccount ? `• • • • • ${valueAccount}` : '----'}
            </Typography>
          </Box>
        </AfterBottomRow>
        <AfterBottomRow className="content icon_available">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('finance.transaction.details.summaryCard.routing')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2" component={'p'} color={'gray.500'} fontWeight={300}>
              {valueRouting ?? '----'}
            </Typography>
          </Box>
        </AfterBottomRow>
        <AfterBottomRow className="content icon_available">
          <Box className="content_right">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              Bank Name
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography variant="h2" component={'p'} color={'gray.500'} fontWeight={300}>
              Evolve Bank & Trust
            </Typography>
          </Box>
        </AfterBottomRow>
        <AfterBottomRow className="content have_two_row">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('finance.transaction.details.summaryCard.method')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography
              variant="h2"
              component={'p'}
              color={'gray.500'}
              marginBottom={1}
              textAlign={'right'}
              fontWeight={300}
            >
              {valueMethod ?? '----'}
            </Typography>
          </Box>
        </AfterBottomRow>

        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('finance.transaction.details.summaryCard.transactionID')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography
              variant="h2"
              component={'p'}
              color={'gray.500'}
              fontWeight={300}
              sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                  maxWidth: '200px',
                },
              }}
            >
              {valueId ?? '----'}
            </Typography>
          </Box>
        </AfterBottomRow>
        <AfterBottomRow className="content">
          <Box className="content_left">
            <Typography variant="h2Bold" component={'p'} color={'gray.700'}>
              {t('finance.transaction.details.summaryCard.description')}
            </Typography>
          </Box>
          <Box className="content_right">
            <Typography
              variant="h2"
              component={'p'}
              color={'gray.500'}
              fontWeight={300}
              textAlign={'right'}
              sx={{
                [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                  maxWidth: '200px',
                  wordWrap: 'break-word',
                },
              }}
            >
              {valueDescription ?? '----'}
            </Typography>
          </Box>
        </AfterBottomRow>
      </Box>
      <Typography
        component="span"
        variant="h2Bold"
        color={'primary'}
        textTransform={'capitalize'}
        textAlign={'right'}
        marginTop={2}
        marginBottom={2}
        sx={{
          display: 'none',
          alignItems: 'center',
          cursor: 'pointer',
          [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
            fontSize: '14px',
          },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {t('finance.transaction.details.summaryCard.viewRecipetLink')}
        <TransactionRowIconArrow>
          <KeyboardArrowRightIcon color="primary" />
        </TransactionRowIconArrow>
      </Typography>
    </TransactionCardWrapper>
  );
};
